(function ($) {
    $(document).foundation();

    $(document).ready(function () {
        $('body').css('opacity', 1);
        $("#formShowingButton").click(function () {

            $.ajax({
                url: ajax.url,
                type: 'post',
                data: {
                    'action': 'get_application',
                    'adID': adID
                },
                dataType: 'json',
                beforeSend: function () {
                    console.log("wczytywanie");
                    //Ogarnąć jakąś graficzną reprezentację wczytywania rzeczy
                },
                success: function (r) {
                    appendFormFields(r, adID);
                    makeFileInputs();
                },
                error: function (xhr, error) {
                    console.debug(xhr);
                    console.debug(error);
                }
            });

            $("#send-application").show(200);
            $("#ad-form-loading").show();
            $(this).fadeOut(200);
            $('.js-rodo-regulations-text').show();
        });
    });

    /**
     * Funkcja zarządzająca logiką na froncie dla każdego input:file.
     * Używana w wielu miejscach, dlatego zamknięta w nazwę ;]
     * @version 1.0.0
     */
    makeFileInputs();
    function makeFileInputs() {
        $('input:file').each(function () {
            var label = $("label[for='" + $(this).attr('id') + "']").css('display', 'none');
            var initText = $("label[for='" + $(this).attr('id') + "']").text();

            var $this = $(this);
            var $fileContainer = $('<div class="file__container"></div>');
            var $text_file = $('<input class="file__input-text" type="text" value="' + label.text() + '" />');
            var $uploadbutton = $('<input class="file__button button" type="button" value="Dodaj plik" />');
            var $removeButton = $('<br /><span class="remove_file">Usuń plik</span>');
            $this.wrap($fileContainer);
            $this.parent('.file__container').append($text_file).append($uploadbutton).append($removeButton);
            $this.bind('change focus click', function () {
                if ($this.val() == '') {
                    $this.parent().find('.file__input-text').val(initText);
                }
                else {
                    $this.parent().find('.file__input-text').val($this.val().replace("C:\\fakepath\\", ""));
                }
            });
            $('body').on('click', '.remove_file' , function() {
                $this.val('');
                $this.parent().find('.file__input-text').val(initText);
            })
        });
    }

    AOS.init({
        duration: 500,
    });

    if ($(document).width() > 1200) {
        var s = skrollr.init();
    }

    $('.menu-item-has-children > a').on('click', function (e) {
        e.preventDefault();
    });

    /**
     * Scroll menu
     */
    $submenu = $('.submenu-with-childs');
    var submenuOffset = 0;
    if ($submenu.length > 0) {
        submenuOffset = $('.submenu-with-childs').offset().top;
    }
    $(window).scroll(function () {
        var scroll = $(window).scrollTop(),
            header = $('#mainHader'),
            hh = $('#mainHader').height(),
            $submenu = $('.submenu-with-childs');

        if (scroll >= 250) {
            header.addClass('site-header--scroll');
        } else {
            header.removeClass('site-header--scroll');
        }

        // Submenu
        if ($submenu.length > 0) { 
            if (scroll >= (submenuOffset - hh)) {
                $submenu.addClass('is-fixed');
            } else { 
                $submenu.removeClass('is-fixed');
            }
        }
    });

    var homeSlider = $('#homeSlider');
    homeSlider.slick({
        dots: true,
        infinite: true,
        prevArrow: '<i class="fas fa-chevron-left slick-arrow--left" aria-label="Poprzedni"></i>',
        nextArrow: '<i class="fas fa-chevron-right slick-arrow--right" aria-label="Następny"></i>',
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 500,
        fade: true,
        verticalSwiping: false,

        responsive: [{
            breakpoint: 1025,
            settings: {
                arrows: false
            }
        }]
    }).
    on('afterChange', function (event, slick, currentSlide) {
        var slide = $('.slider__slide').eq(currentSlide);
        var video = slide.find('.slider__video video').attr('id');
        if (video != undefined) {
            var vid = document.getElementById(video);
            vid.play();
        }
    });


    var citesSlider = $('#citesSlider');
    citesSlider.slick({
        dots: true,
        infinite: true,
        prevArrow: '<i class="fas fa-chevron-left slick-arrow--left" aria-label="Poprzedni"></i>',
        nextArrow: '<i class="fas fa-chevron-right slick-arrow--right" aria-label="Następny"></i>',
        // autoplay: true,
        autoplaySpeed: 4000,
        speed: 500,
        fade: true,
        verticalSwiping: false,

        responsive: [{
            breakpoint: 1025,
            settings: {
                arrows: false
            }
        }]
    });



    /** 
     * slider 
     */
    var slider = $('#strategySlider');
    var dots = $('.strategy-slider__nav .dot-nav');
    var panels = $('#strategyGraph .panel');

    //slick init
    slider.slick({
        arrows: false
    });

    slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        dots.each(function () {
            $(this).removeClass('active');
            if ($(this).attr('rel') == nextSlide) {
                $(this).addClass('active');
            }
        });
        panels.each(function () {
            $(this).removeClass('active');
            if ($(this).attr('rel') == nextSlide) {
                $(this).addClass('active');
            }
        });
    })

    //nawigacja slidera
    $('#strategySliderPrev').click(function () {
        slider.slick("slickPrev");
    });
    $('#strategySliderNext').click(function () {
        slider.slick("slickNext");
    });
    dots.each(function () {
        $(this).on('click', function (e) {
            e.preventDefault();
            slider.slick("slickGoTo", $(this).attr('rel'));
        })
    });
    panels.each(function () {
        $(this).on('click', function (e) {
            e.preventDefault();
            slider.slick("slickGoTo", $(this).attr('rel'));
        })
    });


    var share_button = '.share__button';

    $(share_button).each(function () {
        var $button = $(this);
        var maxWidth = $button.width();
        $button.css('maxWidth', '45px');

        $button.hover(function () {
            $(this).css('maxWidth', maxWidth + 'px');
        }, function () {
            $(this).css($button.css('maxWidth', '45px'));
        });
    });

    var pins = $('.map-pin'),
        labels = $('.map-point');

    pins.each(function () {
        var pin = $(this),
            target = pin.attr('data-map-target');

        pin.hover(
            function () {
                labels.each(function () {
                    $(this).not(target).addClass('hovered');
                })
            },
            function () {
                labels.each(function () {
                    $(this).removeClass('hovered');
                })
            }
        );
    });

    labels.each(function () {
        var label = $(this),
            target = label.attr('id');

        label.hover(
            function () {
                pins.each(function () {
                    $(this).not('[data-map-target="#' + target + '"]').addClass('hovered');
                    console.log('[data-map-target="#' + target + '"]')
                })
            },
            function () {
                pins.each(function () {
                    $(this).removeClass('hovered');
                })
            }
        );
    });


    $('.js-submenu-more').on('click', function () {
        $(this).toggleClass('is-active');
    });


    /**
     * contact form 7 blokowanie wysyłki na checkboxach
     */

    $('.channel-check input').change(function() {
        var select = false;
        $('.channel-check').each(function() {
            if ($(this).find('input').is(':checked')) {
                select = true;
            }
        })
        if ($('.age-check').is(':checked')) {
            if (select == false) {
                $('#sendForm').attr('disabled', true);
            }
            else {
                $('#sendForm').attr('disabled', false);
            }
        }
    })

    $('.age-check').change(function() {
        if ($(this).is(':checked')) {
            var select = false;
                
            $('.channel-check').each(function() {
                if ($(this).find('input').is(':checked')) {
                    select = true;
                }
            })
            
            if (select == false) {
                $('#sendForm').attr('disabled', true);
            }
            else {
                $('#sendForm').attr('disabled', false);
            }
        }
    });

    // Formularz zgloszenie
    $(document).on('click', '.js-form-app-regulations-btn', function (e) { 
        e.preventDefault();

        var $this = $(this),
            $regulation = $('.js-form-app-regulations');
        
        if ($this.hasClass('is-close')) {
            $this.removeClass('is-close');
            $this.text('Pokaż regulamin');
            $regulation.removeClass('is-open');
        } else { 
            $this.addClass('is-close');
            $this.text('Zwiń regulamin');
            $regulation.addClass('is-open');
        }
    })

    // Zaznaczenia checkbox w przypadku uzupełnienia email
    $(document).on('keyup', 'input[name="your-email"]', function (e) { 

        var $acceptMailCheckbox = $('input[name="accept-mail[]"]');

        if( $acceptMailCheckbox.length < 1 ) return;

        if( $(this).val() ){
            $acceptMailCheckbox.prop('checked', true);
        }else{
            $acceptMailCheckbox.prop('checked', false);
        }

    });

    /**
     * Formularz reklamacja
     */

    // Tooltips text
    var tooltip_text = "Wymagany kod znajduje się na spodzie paczki na czarnym tle lub na boku paczki na czarnym tle - jak na wskazanym zdjęciu.",
        tooltip_second_text = "Wymagany kod znajduje się na spodzie paczki na czarnym tle lub na boku paczki na czarnym tle.",
        tooltip_third_text = "Wymagany kod znajduje się na paczce.",
        tooltip_fourth_text = "Wymagany kod znajduje się na spodzie urządzenia lub na spodzie kartonowego opakowania – jak na wskazanym zdjęciu"

    var i1 = [
            tooltip_text,
            'tooltip_img_1.jpeg'
        ],
        i2 = [
            tooltip_text,
            'tooltip_img_2.jpeg'
        ],
        i3 = [
            tooltip_text,
            'tooltip_img_3.jpeg'
        ],
        i4 = [
            tooltip_text,
            'tooltip_img_4.jpeg'
        ],
        i5 = [
            tooltip_text,
            'tooltip_img_5.jpeg'
        ],
        i6 = [
            tooltip_text,
            'tooltip_img_6.jpeg'
        ],
        i7 = [
            tooltip_second_text,
            false
        ],
        i8 = [
            tooltip_third_text,
            false
        ],
        i9 = [
            tooltip_fourth_text,
            'tooltip_img_9.jpeg'
        ]

    var selectTopic = '#js-select-topic',
        assortmentsBlock = '#js-assortments-block',
        brandsBlock = '#js-brands-block',
        variantsBlock = '#js-variants-block',
        codeProdBlock = '#js-code-prod-block',
        selectedAssortVal = false,
        selectedBrandVal = false,
        selectedVarVal = false,
        $img = $('#js-cf-image')
        dataSrc = $img.attr('data-src'),
        $messageTooltip = $('#js-message-tooltip-init')

    // Reklamacje
    $(document).on('change', selectTopic, function(e) {
        var $this = $(this),
            selectedTopicVal = $this.find(":selected").val()

        if ( selectedTopicVal == "Reklamacje" ) {
            $(assortmentsBlock).css('display','block')
            $("#js-text").show()
        } else {
            $("#js-text").hide()
            $(assortmentsBlock).css('display','none')
            $(brandsBlock).css('display','none')
            $(variantsBlock).css('display','none')
            $(codeProdBlock).css('display','none')
            $messageTooltip.css('display','none')
        }
    })

    // Assortyment
    $(document).on('change', assortmentsBlock, function(e) {
        var $this = $(this);
        selectedAssortVal = $this.find(":selected").val()

        $('select[name="brands"],select[name="variants"]').html($('<option>', { 
            value: "",
            text : "Wybierz..." 
        }));
        $(brandsBlock).css('display','none')
        $(variantsBlock).css('display','none')
        $(codeProdBlock).css('display','none')
        $messageTooltip.css('display','none')

        if ( selectedAssortVal ) {
            $(brandsBlock).css('display','block')
            _renderBrandsOptions(selectedAssortVal);
        } else {
            $(brandsBlock).css('display','none')
        }
    })

    // Marka
    $(document).on('change', brandsBlock, function(e) {
        var $this = $(this);
        selectedBrandVal = $this.find(":selected").val()

        $img.css('display','none')
        $(codeProdBlock).css('display','none')
        $('select[name="variants"]').html($('<option>', { 
            value: "",
            text : "Wybierz..." 
        }));

        if ( selectedBrandVal ) {
            $(variantsBlock).css('display','block')
            _renderVariantsOptions(selectedBrandVal);
        } else {
            $(variantsBlock).css('display','none')
        }
    })

    // Wariant
    $(document).on('change', variantsBlock, function(e) {
        var $this = $(this),
            $tooltip = $('.js-code-tooltip')

        selectedVarVal = $this.find(":selected").val()
        $img.css('display','none')

        if ( selectedVarVal ) {
            $(codeProdBlock).css('display','block')
            switch(selectedVarVal) {
                case 'King Size':
                case '100':
                    $tooltip.text(i1[0]);
                    $img.attr('src', dataSrc + i1[1])
                    break;
                case 'SSL':
                    $tooltip.text(i2[0]);
                    $img.attr('src', dataSrc + i2[1])
                    break;
                case '70':
                    $tooltip.text(i3[0]);
                    $img.attr('src', dataSrc + i3[1])
                    break;
                case '80 g':
                case '30 g':
                    $tooltip.text(i4[0]);
                    $img.attr('src', dataSrc + i4[1])
                    break;
                case '50 g':
                    $tooltip.text(i5[0]);
                    $img.attr('src', dataSrc + i5[1])
                    break;
                case 'Menthol':
                case 'Ice':
                    $tooltip.text(i6[0]);
                    $img.attr('src', dataSrc + i6[1])
                    break;
                case 'West':
                case 'Columbus':
                case '100 sztuk':
                    $tooltip.text(i7[0]);
                    $img.css('display','none')
                    break;
                default:
                    console.log('empty')
            }

            selectedAssortVal = $(assortmentsBlock).find(":selected").val()
            if (selectedAssortVal == "Tubki") {
                $tooltip.text(i8[0]);
            }
            if (selectedAssortVal == "Papierosy jednorazowe") {
                $tooltip.text(i9[0]);
                $img.attr('src', dataSrc + i9[1])
            }

        } else {
            $(codeProdBlock).css('display','none')
        }
    })

    // Kod produkcji
    $(document).on('keyup', 'input[name="code_prod"]', function() {
        $messageTooltip.css('display', 'inline-block')
    });

    // Render brands options
    function _renderBrandsOptions(selectedAssortVal) {
        var assortment = false;

        switch(selectedAssortVal) {
            case 'Papierosy':
                assortment = assort_1
                break;
            case 'Tytonie':
                assortment = assort_2
                break;
            case 'Tubki':
                assortment = assort_3
                break;
            case 'Bibułki':
                assortment = assort_4
                break;
            case 'Karty Rizla':
                assortment = assort_5
                break;
            case 'Maszynka do najbijania tytoniu':
                assortment = assort_6
                break;
            case 'Papierosy jednorazowe':
                assortment = assort_7
                break;
            default:
                console.log('empty')
        }
        
        if ( assortment ) {
            $.each(assortment, function (i, item) {
                $('select[name="brands"]').append($('<option>', { 
                    value: item.value,
                    text : item.text 
                }));
            })
        }

        return assortment;
    }

    // Render variants options
    function _renderVariantsOptions(selectedBrandVal) {
        var assortment = false;
        
        switch(selectedBrandVal) {
            case 'P&S':
            case 'Davidoff':
            case 'West':
                switch(selectedAssortVal) {
                    case 'Papierosy':
                        assortment = variant_1
                        break;
                    case 'Tytonie':
                        assortment = variant_5
                        break;
                    case 'Tubki':
                        assortment = variant_7
                        break;
                    case 'Maszynka do najbijania tytoniu':
                        assortment = variant_10
                        break;
                }
                break;
            case 'Mocne':
                assortment = variant_2
                break;
            case 'R1':
                assortment = variant_3
                break;
            case 'Gauloises':
            case 'L&B':
            case 'Cabinet':
            case 'Mars':
                assortment = variant_4
                break;
            case 'Golden Virginia':
                assortment = variant_6
                break;
            case 'Columbus':
                assortment = variant_8
                break;
            case 'Rizla':
                assortment = variant_9
                break;
            case 'BlueBar':
                assortment = variant_11
                break;
            default:
                console.log('empty')
        }

        if ( assortment ) {
            $.each(assortment, function (i, item) {
                $('select[name="variants"]').append($('<option>', { 
                    value: item.value,
                    text : item.text 
                }));
            })
        }

        return assortment
    }

    /**
     * Asortyment
     */
    // papierosy
    var assort_1 = [{value: "P&S",text: "P&S"},{value: "Davidoff",text: "Davidoff"},{value: "Mocne",text: "Mocne"},{value: "R1",text: "R1"},{value: "Gauloises",text: "Gauloises"},{value: "L&B",text: "L&B"},{value: "Cabinet",text: "Cabinet"},{value: "Mars",text: "Mars"},{value: "West",text: "West"}]
    // tytonie
    var assort_2 = [{value: "West",text: "West"},{value: "P&S",text: "P&S"},{value: "Golden Virginia",text: "Golden Virginia"}]
    // tubki
    var assort_3 = [{value: "P&S",text: "P&S"},{value: "West",text: "West"}]
    // bibulki
    var assort_4 = [{value: "Columbus",text: "Columbus"}]
    // karty
    var assort_5 = [{value: "Rizla",text: "Rizla"}]
    // Nabijania
    var assort_6 = [{value: "West",text: "West"}]
    // Papierosy jednorazowe
    var assort_7 = [{value: "BlueBar",text: "BlueBar"}]

    /**
     * Wariant
     */
    // P&S / Davidoff 
    var variant_1 = [{value: "King Size",text: "King Size"},{value: "SSL",text: "SSL"},{value: "100",text: "100"}]
    // Mocne 
    var variant_2 = [{value: "70",text: "70"}]
    // R1
    var variant_3 = [{value: "King Size",text: "King Size"},{value: "SSL",text: "SSL"}]
    // Gauloises / L&B / Cabinet / Mars
    var variant_4 = [{value: "King Size",text: "King Size"}]
    // West / P&S tytonie
    var variant_5 = [{value: "80 g",text: "80 g"},{value: "30 g",text: "30 g"}]
    // Golden
    var variant_6 = [{value: "50 g",text: "50 g"}]
    // P&S / West tubki
    var variant_7 = [{value: "100 sztuk",text: "100 sztuk"}]
    // Columbus
    var variant_8 = [{value: "Columbus",text: "Columbus"}]
    // Rizla 
    var variant_9 = [{value: "Menthol",text: "Menthol"},{value: "Ice",text: "Ice"}]
    // West nabijania
    var variant_10 = [{value: "West",text: "West"}]
    // Bluebar
    var variant_11 = [
        {value: "Grape ice",text: "Grape ice"},
        {value: "Blueberry ice",text: "Blueberry ice"},
        {value: "Banana ice",text: "Banana ice"},
        {value: "Strawberry ice",text: "Strawberry ice"},
        {value: "Watermelon ice",text: "Watermelon ice"},
        {value: "Peach ice",text: "Peach ice"},
        {value: "Mango ice",text: "Mango ice"},
        {value: "Kiwi passion fruit",text: "Kiwi passion fruit"}
    ]

    // Wyswietlenie obrazku z podpowiedzią
    $('#js-init-code-tooltip').on('show.zf.tooltip', function(){
        var disabledVars = ["West","Columbus","100 sztuk"];
        if (!disabledVars.includes(selectedVarVal) ) {
            $img.css('display','inline-block')
        }
    });
    // Ukrycie obrazku z podpowiedzią
    $('#js-init-code-tooltip').on('hide.zf.tooltip', function(){
        $img.css('display','none')
    });

})(jQuery);