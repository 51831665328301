function watchApplicationForm(wrongInput){
    submitButtonSwitcher();
    $("#send-application .input").blur(function(){
        //Pobieranie listy walidacji z atrybutu data pola
        var validationsList;
        var validationsStates = new Array();
        var isCorrect = true;
        validationsList = $(this).data("validations");
        console.log(validationsList);

        //Stosowanie pobranej listy walidacji
        validationsList.forEach(function(validation, validation_index, validation_array){
            var value = $(this).val();
            switch(validation.Type){
                case "Required":
                    if( val ){
                        validationsStates[validation_index] = true;
                    } else {
                        validationsStates[validation_index] = false;
                    }
                    break;
                case "MaxLength":
                    if( val.length < validation.Value ){

                        validationsStates[validation_index] = true;
                    } else {

                        validationsStates[validation_index] = false;
                    }
                    break;
            }

        });

        validationsStates.forEach(function(state, index, array){



        });

        submitButtonSwitcher();

    });
}

function submitButtonSwitcher(){
    var correctFlag = true;
    var button = $("#send-application #submit");
    $("#send-application .input").each(function(){
        if(!$(this).data("correct")){
            correctFlag = false;
        }
    });

    if(correctFlag)
        button.removeAttr('disabled');
    else
        button.attr('disabled', 'true');

}
