function appendFormFields(r, adID){

    var formSettings =  r.output.pifStructure.FormSettings;
    var formID = formSettings.FormId;
    var formQuestions =  r.output.pifStructure.FormQuestions.Question;
    var form = $("#send-application>.column>.row");
    var selectQuestions = new Array();

    //Wstawianie nowych pól do formularza
    formQuestions.forEach(function(element, index, array){

        var fieldType,
            newdiv,
            maxLength;

        //Definiowanie zasad walidacji dla inputa
        var validations = element.ValidationRules.ValidationRule;

        //fix - jeżeli walidacja jest jedna to nie przychodzi w arrayu
        if(!Array.isArray(validations)){
            var tmp = validations;
            validations = new Array();

            validations[0] = tmp;
        }

        //Sprawdzenie, czy regula walidacyjna jest tą, która zawiera informacje na temat typu pola
        validations.forEach(function(validation, validation_index, validation_array){
            if(validation.Type == "Type"){
                fieldType = validation.Value;
                validation_array.splice(validation_index, 1);
                return 0;
            }
            if(validation.Type == "MaxLength"){
                maxLength = validation.Value;
            }
         });
        
        if(typeof fieldType === undefined || fieldType === null){
            console.error('Nie zainicjowano typu pola');
            return false;
        }

        //Dodawanie walidacji do tablicy: required, jeżeli input jest required
        if(element.IsRequired){
            var tmp = {
                Type: "Required",
                Value: true
            }
            validations.push(tmp);

            //Dodawanie gwiazdki do labela pola
            element.Label += " *";
        }


        //Przerobienie zestawu walidacji na stringa, aby potem można było go zapisać w atrybucie data inputa
        validationsJSON = JSON.stringify(validations);

        //Definiowanie i podpinanie diva zawierającego input i labela
        if(errors[element.Id])
            form.append('<div class="column small-24 tablet-24 input__container input__container--error"></div>');
        else
            form.append('<div class="column small-24 tablet-24 input__container"></div>');

        newdiv = form.find('.input__container:last-child');
        newdiv.append('<label for="'+element.Id+'" class="input__label">'+element.Label+'</label>');
        newdiv.append('<input type="hidden" name="'+element.Id+'-validations" value=\''+validationsJSON+'\'>');

        //Definiowanie inputa w zależności od jego typu
        //input tp select
        if(fieldType == 'lov'){
            selectQuestions.push(element.Id);

            var select;
            var selectedOrNot;

            newdiv.append('<select type="text" class="input" name="'+element.Id+'" id="'+element.Id+'"></select>');
            select = newdiv.find('select');
            newdiv.append("<span class=\"fas fa-chevron-down\"></span>");

            element.Options.Option.forEach(function(element, index, array){

                if(element.Label == "Polska") selectedOrNot = 'selected';
                else                          selectedOrNot = '';

                select.append('<option value="'+element.Id+'" '+selectedOrNot+'>'+element.Label +'</option>');
            });
        } else {
            //Sprawdzenie dokładnego typu pola
            switch (fieldType){
                case 'free_text':
                    newdiv.append('<input type="text" class="input" name="'+element.Id+'" id="'+element.Id+'" data-correct="false">');
                    break;
                case 'e-mail':
                    newdiv.append('<input type="email" class="input" name="'+element.Id+'" id="'+element.Id+'">');
                    break;
                case 'phone':
                    newdiv.append('<input type="number" class="input" name="'+element.Id+'" id="'+element.Id+'">');
                    break;
            }
        }

        if(errors[element.Id] == "required"){
            newdiv.append('<span class="input__error-info">Pole wymagane</span>');
        } else if (errors[element.Id] == "tooLong"){
            newdiv.append('<span class="input__error-info">Długość tego pola nie może przekroczyć '+maxLength+'</span>');
        }

    });

    //DOdawanie szczegółów nt. forma w ukrytych inputach
    form.append('<input type="hidden" class="input" name="formID" value="'+formID+'">');
    form.append('<input type="hidden" class="input" name="adID" value="'+adID+'">');
    form.append('<input type="hidden" class="input" name="selectQuestions" value="'+selectQuestions+'">');
    form.append('<input type="hidden" class="input" name="adLink" value="'+adLink+'">');

    //Dodanie przycisku wysłania i powrotu do wszystkich ofert pracy
    form.append(cvInput);
    if(errors['file'] == 'error'){
        form.append('<div class="column small-24 tablet-24 input__container">' +
                    '   <span class="form-sending-alert">Musisz przesłać plik w formacie pdf</span>' +
                    '</div>');
    }

    form.append(
        '<div class="column small-24 medium-12 input__container">\n' +
        '                    <span class="input__required">* Pola wymagane</span>\n' +
        '                </div>'+
        '<div class="column small-24 medium-24 input__container input__container--button">' +
        '    <input type="submit" class="button" value="Wyślij zgłoszenie" id="submit">' +
        '</div>');
    form.append(moreOffers);

    //wywołanie funkcji walidacyjnej po stronie frontendu
    // watchApplicationForm();
    $("#ad-form-loading").fadeOut(200);
}




